import './App.css';
import './signup';
import Signup from './signup';

function App() {
  
  return (
    <div className="App">
      <Signup/>
    </div>
  );
}

export default App;